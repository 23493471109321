<!-- 首页 -->
<template>
  <div class="huayang"
    style="height: 100%;">
    <!-- 标题 -->
    <van-nav-bar title="中介超市"
      right-text="登录"
      fixed
      placeholder
      @click-right="onClickRight" />
    <!-- 侧边栏 -->
    <van-sidebar v-model="activeKey"
      style="
        width: 25%;
        float: left;
        position: fixed;
        overflow-y: scroll;
        height: 87%;">
      <van-sidebar-item title="全部"
        @click="classifyClick(undefined)" />
      <van-sidebar-item v-for="item in classifyList"
        :key="item"
        :title="item.Name"
        @click="classifyClick(item.Coding)" />
    </van-sidebar>
    <!-- 内容 -->
    <div style="float: right; width: 75%">
      <div class="list"
        v-for="item in list"
        :key="item.AId">
        <router-link :to="'/huayang/huayang/company/' + item.AId">
          <div style="
              display: inline-block;
              width: 90%;
              margin-left: 15px;">
            <div style="font-size: 16px">{{item.Name}}</div>
            <div style="font-size: 14px; margin: 5px 0px">
              <span style="color:#188dfa;padding-right:5px"
                v-for="items in item.ClassifyName"
                :key="items">{{items}}</span>
            </div>
            <div style="font-size: 12px; margin: 10px 0px;"
              class="list_tabs van-multi-ellipsis--l3">
              <span>{{item.Summary}}</span>
            </div>
          </div>
        </router-link>
      </div>
      <div class="hidden">
        ──── 已经没有更多 ────
      </div>
    </div>
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o"
          to="/huayang/classroom">知识大讲堂</van-tabbar-item>
        <van-tabbar-item icon="qr"
          to="/huayang/serve">资源看板</van-tabbar-item>
        <van-tabbar-item icon="apps-o"
          to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o"
          to="/huayang/interaction">区企互动</van-tabbar-item>
        <van-tabbar-item icon="phone-circle-o"
          to="/huayang/lianluo">联系我们</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { WeGetAgencyPage } from "@/api/Hyapi";
import { GetCodeEntrysList } from "@/api/oldman";
import { getaccToken } from "@/utils/auth";
export default {
  data () {
    return {
      activeKey: 0,
      active: 2,
      classifyList: {}, //行业列表
      searchList: { //中介机构查询参数
        page: 1,
        limit: 999,
        classify: undefined,
      },
      list: {  //中介机构列表

      },
    };
  },
  methods: {
    // 获取行业列表
    getCodeEntrysList () {
      GetCodeEntrysList({ kind: 256 }).then((res) => {
        this.classifyList = res.data.data;
      })
    },
    // 行业列表点击
    classifyClick (e) {
      this.searchList.classify = e;
      this.getPush();
    },
    // 跳转登录
    onClickRight () {
      if (getaccToken() != undefined) {
        this.$router.push({ name: 'firm' });
      } else {
        this.$router.push("/huayang/firmlogin");
      }
    },
    // 获取中介列表
    getPush () {
      WeGetAgencyPage(this.searchList).then((res) => {
        console.log(res);
        this.list = res.data.data;
        for (let i = 0; i < this.list.length; i++) {
          var classArr = this.list[i].ClassifyName.split(",");
          this.list[i].ClassifyName = classArr
        }
      })
    },
  },
  mounted () {
    this.getCodeEntrysList();
    this.getPush();
  }
};
</script>
<style>
.huayang .van-sidebar-item--select {
  color: #188dfa;
  background: #f8f9f9;
  font-size: 16px;
}
.huayang .van-sidebar-item--select::before {
  background-color: #188dfa;
}
.huayang .list {
  width: 90%;
  padding: 7px;
  background: #f0f1f3;
  border-radius: 10px;
  margin: 8px 16px 8px 8px;
}
.huayang .list img {
  width: 70px;
  height: 70px;
}
.huayang .list div {
  color: #000;
}
.list_tabs span {
  color: #333;
}
.hidden {
  margin: 15px 0px;
  text-align: center;
  color: #666;
}
</style>